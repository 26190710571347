import React from 'react'
import { Link } from 'gatsby'
import ProjectSection from './ProjectSection'

import './CenteredVideo.css'
import Content from './Content'

class CenteredVideo extends React.Component {  
  render() {
    const { title, backlink, video, youtubeVideoId, excerpt, items = [] } = this.props
    return (
      <div className="CenteredVideo CenteredVideo-thin">
        <div className="CenteredVideo-wrap">
          <div className="CenteredVideo-title">
            {title && <h1 className="taCenter">{title}</h1>}
            {excerpt && <p className="taCenter"><Content src={excerpt} /></p>}
            {backlink && (
              <Link
                className="back"
                to={backlink}
                onClick={() => window.history.back()}
              >
                Back to all
              </Link>
            )}
          </div>          
          {youtubeVideoId && (
            <div className="video relative">
              <iframe
                title={title}
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}    
          {!youtubeVideoId && video && (
            <div className="video relative">
              <iframe
                title={title}
                src={`https://player.vimeo.com/video/${video}`}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}      
        </div>
        {!!items && (
          <div className="project-section CenteredVideo-thumb-section">
            <ProjectSection projects={items} />
          </div>
        )}
      </div>
    )
  }
}

export default CenteredVideo
