import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import CenteredVideo from '../components/CenteredVideo'
import Footer from '../components/Footer'

export const SingleMotionGraphicsTemplate = ({ title, excerpt, video, youtubeVideoId }) => {
  return (
    <Fragment>
      <CenteredVideo
        title={title}
        excerpt={excerpt}
        video={video}
        youtubeVideoId={youtubeVideoId}
        backlink="/project-categories/motion-graphics/#no-heading"
      />
      <Footer />
    </Fragment>
  )
}

const SingleMotionGraphics = ({ data: { project } }) => {
  return (
    <Layout
      meta={project.frontmatter.meta || false}
      title={project.frontmatter.title || false}
    >
      <SingleMotionGraphicsTemplate
        {...project}
        {...project.frontmatter}
        body={project.html}
      />
    </Layout>
  )
}

export default SingleMotionGraphics

export const pageQuery = graphql`
  query SingleMotionGraphics($id: String!) {
    project: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        excerpt
        video
        youtubeVideoId
      }
    }
  }
`
